@import '../../styles/styles.scss';


@keyframes LoaderAnimation {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader {
    display: inline-block;
    height: 32px;
    width: 32px;
    border-top: 5px solid $primary;
    border-right: 5px solid $primary;
    border-bottom: 5px solid transparent;
    border-left: 5px solid $primary;
    border-radius: 50%;
    animation: LoaderAnimation 2s linear infinite;
}