// .full-body-container{
//   @include media-breakpoint-up(lg){
//     padding-top: 4rem;
//   }
// }

footer{
  padding: 1rem;

  @include media-breakpoint-up(md){
    position:absolute;
    bottom: 0;
    left:0;
    right: 0;
  }
}

.wt-main-nav-logo {
  height: 75px;
  width: 75px;
}

.wt-nav-logo{
  border: 2px solid #FFF;
  border-radius: 50%;
  overflow: hidden;
}

.outlet-body{
  z-index: 1;
}

.outlet-nav{
  z-index: 0;
  position:fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0);
}
