// .ft-sect{
//   @include media-breakpoint-up(md){
//     min-height: 50vh;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//   }
// }

.legal{
  font-size: 0.75rem;
  color: $gray-500;
  > a{
    color: $gray-500;
  }
}

.text-sm{
  font-size: 0.85rem;
}

.text-lg{
  font-size: 1.25rem;
}

a{
  &.inverse{
    color: $white;
    @include hover-focus {
      color: $link-color;
    }
  }
}