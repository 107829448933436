@keyframes borderBreathe{
  0% {
    border-color: transparent;
  }
  50% {
    border-color: $primary;
  }
  100% {
    border-color: transparent;
  }
}
